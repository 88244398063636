@tailwind base;
@tailwind components;
@tailwind utilities;


.invoice-details-cont {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 28px #00000005;
  border-radius: 5px;
  opacity: 1;
}

.summary-cont {
  border-collapse: collapse;
  width: 100%;
}

.summary-table {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* Toggle CSS */
/* ToggleSwitch.css */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


/* Toggle CSS */

@media print {
  .hidden-print {
    display: none !important;
  }
  .invoice-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-top: 30px;
  }
}

@media (min-width: 1024px) {
  .invoice-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
    margin-top: 30px;
  }
}

.table-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  overflow-x: auto;
}

.table {
  padding: 5px;
}

.table th {
  text-align: left;
  font-weight: bold;
  display: block;
  width: 100%;
}
.table-headers {
  display: block;
  width: max-content;
  margin-right: 10px;
}
.analyzer-table-header {
  font-weight: bold;
  background-color: rgba(191, 219, 254, 0.8);
}

.analyzer-table {
  width: 100%
}

.analyzer-table tr td {
  font-size: 1rem;
  padding: 5px 25px;
  border: 1px solid black;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
